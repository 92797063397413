<template>
  <div>
    <page-title :heading=heading :subheading="subheading" :icon=icon></page-title>
    <div class="content">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-10" >
          <div class="main-card mb-lg-4 card">
            <div class="card-body">
              <VueElementLoading :active="showLoading" spinner="ring" color="var(--primary)" />
              <p class="m-4" v-if="sessions.data.length === 0">Няма намерени резултати</p>
              <div v-else>
                <ul class="todo-list-wrapper list-group list-group-flush" v-for="session in sessions.data" v-bind:key="session.id">
                  <li class="list-group-item">
                    <div class="widget-content p-0">
                      <div class="todo-indicator" v-bind:class="getStatusColor(session.status)">
                      </div>
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left ml-3">
                          <div class="widget-heading">
                            {{session.title}}
                          </div>
                          <div class="widget-subheading">
                            <h6 class="font-size-md font-weight-normal mb-1">
                              Начало: {{ formatFullDate(offsetDate(session.start)) }}
                            </h6>
                            <h6 class="font-size-md font-weight-normal mb-1">
                              Край: {{ formatFullDate(offsetDate(session.end)) }}
                            </h6>
                          </div>
                        </div>
                        <div class="widget-content-right">
                          <div class="d-inline-block">
                            <router-link :to="{ name: 'qna-session', params: { sessionId: session.id, sessionTitle: session.title, sessionIsModerated: session.is_moderated } }"
                                         class="nav-link" style="color: var(--primary)">
                              <font-awesome-icon icon="comments" class="mr-1"/>
                            </router-link>
                            <router-link :to="{ name: 'qna-session-external', params: { sessionId: session.id, sessionTitle: session.title, sessionIsModerated: session.is_moderated } }"
                                         class="nav-link" style="color: var(--primary)">
                              <font-awesome-icon icon="arrow-right"  class="mr-1"/>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="d-block text-right card-footer">
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-pagination v-if="sessions.total>0"
                  id="pagination"
                  align="center"
                  :total-rows="sessions.total"
                  @input="changePage()"
                  v-model="currentPage"
                  :per-page="sessions.per_page">
    </b-pagination>
  </div>
</template>


<script>
import PageTitle from "../../Layout/Components/PageTitleWithBackButton.vue";
import VueElementLoading from "vue-element-loading";
import QnASessionsService from "../../services/qna_sessions.service";
import swal from "sweetalert";
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faListAlt,
  faComments,
    faArrowRight
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import CountryFlag from "vue-country-flag";
import DateHelper from "../../util/dateHelper";

library.add(
    faListAlt,
    faComments,
    faArrowRight
);

export default {
  components: {
    PageTitle,
    VueElementLoading,
    'font-awesome-icon': FontAwesomeIcon,
    CountryFlag,
  },
  mixins: [DateHelper],
  data: () => ({
    TARGET_TYPE_EVENT: 0,
    TARGET_TYPE_PANEL: 1,

    currentUser: {},

    heading: 'Въпроси и отговори',
    subheading: '',
    icon: 'pe-7s-comment icon-gradient bg-happy-itmeo',

    showLoading: false,

    eventId: -1,
    targetId: -1,
    targetType: -1,

    sessions: {
      data: []
    },
    currentPage: 1,
    languages: [],
    answersLink: "",

    statusColors:[
      'bg-secondary',
      'bg-success',
      'bg-danger'
    ]
  }),

  mounted() {
    this.targetId = this.$route.params.targetId;

    if(this.$route.name === "event-qna") {
      this.targetType = this.TARGET_TYPE_EVENT;
      this.getEventSessions()
    }
    else if(this.$route.name === "panel-qna") {
      this.eventId = this.$route.params.eventId;
      this.targetType = this.TARGET_TYPE_PANEL;
      this.getPanelSessions()
    }
  },

  methods:
  {
    refreshList()
    {
      this.currentPage = 1;

      if(this.targetType === this.TARGET_TYPE_EVENT) {
        this.getEventSessions()
      }
      else if(this.targetType === this.TARGET_TYPE_PANEL) {
        this.getPanelSessions()
      }
    },

    refreshCurrentPage()
    {
      if(this.targetType === this.TARGET_TYPE_EVENT) {
        this.getEventSessions()
      }
      else if(this.targetType === this.TARGET_TYPE_PANEL) {
        this.getPanelSessions()
      }
    },

    getEventSessions() {
      this.showLoading = true;
      QnASessionsService.getEventSessions(this.targetId, this.currentPage).then((response) =>
      { response
        this.sessions = response.data.response.results;

        var eventTitle = response.data.response.event_title;
        this.subheading = "Събитие \"" + eventTitle + "\"";

        this.showLoading = false;
      },
      error => {
        this.showLoading = false;
        this.handleError(error);
      })
    },

    getPanelSessions() {
      this.showLoading = true;

      QnASessionsService.getPanelSessions(this.targetId, this.currentPage).then((response) =>
          { response
            this.sessions = response.data.response.results;

            var eventTitle = response.data.response.event_title;
            var panelTitle = response.data.response.panel_title;
            this.subheading = "Събитие \"" + eventTitle + "\" > Панел \"" + panelTitle + "\"";

            this.showLoading = false;
          },
          error => {
            this.showLoading = false;
            this.handleError(error);
          })
    },

    handleError(error)
    {
      let errorMsg =
          error.message || (error.response && error.response.data && error.response.data.message) ||
          error.toString();
      if(error.response.data.error.message)
      {
        swal("Грешка", error.response.data.error.message, "error");
      }
      if (error.response && error.response.status === 500)
      {
        console.log(errorMsg)
      }
    },

    getStatusColor(status)
    {
      if(this.statusColors[status])
      {
        return this.statusColors[status];
      }
    },
  },
}
</script>