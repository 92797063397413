import api from "./api";
import TokenService from "./auth/token.service";

class QnASessionsService
{
    BASE_PATH = '/moderator/event';

    //List
    getEventSessions(eventId, page) {
        return api
            .get(this.BASE_PATH + '/' + eventId+'/qna',{params: {
                    target_type: 0,
                    target_id: eventId,
                    page: page
                }});
    }

    getPanelSessions(panelId, page) {
        return api
            .get(this.BASE_PATH + '/panel/' + panelId + '/qna',{params: {
                    target_type: 1,
                    target_id: panelId,
                    page: page
                }});
    }
    getSingle(id) {
        return api
            .get(this.BASE_PATH + '/qna/' + id);
    }
    getSessionMessages(sessionId,page)
    {
        return api
            .get(this.BASE_PATH + '/qna/'+sessionId+'/messages',{params: {
                    page: page
                }});
    }
    getSessionLatestMessages(sessionId,lastMessageId)
    {
        return api
            .get(this.BASE_PATH + '/qna/'+sessionId+'/messages/latest',{params: {
                    message_id: lastMessageId
                }});
    }
    sendMessage(sessionId, message)
    {
        var params = {
            message: message,
            message_type: 0, //Regular
            is_anonymous: 0
        }


        return api
            .post(this.BASE_PATH + '/qna/'+sessionId+'/messages',params);
    }
    sendMessageResponse(sessionId,responseToId, message)
    {
        var params = {
            message: message,
            response_to: responseToId,
            message_type: 1,//Response
            is_anonymous: 0
        }


        return api
            .post(this.BASE_PATH + '/qna/'+sessionId+'/messages',params);
    }
    setMessageStatus(messageId,status)
    {
        var params = {
            status: status,
        }

        return api
            .post(this.BASE_PATH + '/qna/message/'+messageId+'/status',params);
    }
}

export default new QnASessionsService();
